import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
    json,
    Links,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useRouteLoaderData,
    useRouteError,
    isRouteErrorResponse,
    useNavigate,
    useMatches,
    type UIMatch,
} from "@remix-run/react";
import "./tailwind.css";
import type { LoaderFunctionArgs } from "@remix-run/cloudflare";
import ErrorPage from "./pages/auth/auth-code-error";
import CookieConsent from "./components/cookie-consent";
import type { HandleWithScripts } from "./types/handle";

export async function loader({ context }: LoaderFunctionArgs) {
    return json({
        ENV: {
            SUPABASE_URL: context.cloudflare.env.VITE_SUPABASE_URL,
            SUPABASE_ANON_KEY: context.cloudflare.env.VITE_SUPABASE_ANON_KEY,
            VITE_TURNSTILE_SITE_KEY: context.cloudflare.env.VITE_TURNSTILE_SITE_KEY,
            NODE_ENV: context.cloudflare.env.ENV,
        },
    });
}

// Update the window.ENV interface with the environment variables
declare global {
    interface Window {
        ENV?: {
            SUPABASE_URL: string;
            SUPABASE_ANON_KEY: string;
            VITE_TURNSTILE_SITE_KEY: string;
            NODE_ENV: "development" | "production";
        };
    }
}

const ScreenSizeIndicator = () => {
    return (
        <div className="fixed bottom-0 right-0 m-4 p-2 bg-gray-800 text-white text-sm rounded-3xl">
            <div className="block sm:hidden">xs</div>
            <div className="hidden sm:block md:hidden">sm</div>
            <div className="hidden md:block lg:hidden">md</div>
            <div className="hidden lg:block xl:hidden">lg</div>
            <div className="hidden xl:block 2xl:hidden">xl</div>
            <div className="hidden 2xl:block">2xl+</div>
        </div>
    );
};

// Typescript predicate to check if scripts are defined in the handle
function isHandleWithScripts(handle: unknown): handle is HandleWithScripts {
    return (
        typeof handle === "object" &&
        handle !== null &&
        "scripts" in handle &&
        handle.scripts !== undefined &&
        Array.isArray(handle.scripts) &&
        handle.scripts.every((script) => typeof script === "object" && "src" in script)
    );
}

function isMatchWithScriptsHandle(match: UIMatch): match is UIMatch<unknown, HandleWithScripts> {
    return isHandleWithScripts(match.handle);
}

export function Layout({ children }: { children: React.ReactNode }) {
    const data = useRouteLoaderData<typeof loader>("root");
    const matches = useMatches();

    const scriptMatches = matches.filter((match) => isMatchWithScriptsHandle(match));

    const { ENV } = data ?? {};

    return (
        <html lang="en">
            <head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <Meta />
                <Links />
                <script
                    defer
                    data-domain="tailoredcv.ai"
                    src="https://s1.cmsnebula.com/js/script.tagged-events.revenue.js"
                />
                {/* Embed any custom scripts if they are defined in the mathces */}
                {scriptMatches.map((match) => {
                    return match.handle.scripts.map((script) => {
                        return <script key={script.src} defer={script.defer} src={script.src} />;
                    });
                })}
            </head>
            <body>
                {children}
                <ScrollRestoration />
                <Scripts />
                <CookieConsent />
                <script
                    // biome-ignore lint/security/noDangerouslySetInnerHtml: This the recommended way to inject env vars into the client
                    dangerouslySetInnerHTML={{
                        __html: `
                        window.ENV = ${JSON.stringify(ENV)};
                    `,
                    }}
                />
                <script
                    // biome-ignore lint/security/noDangerouslySetInnerHtml: This comes from Lemon Squeezy
                    dangerouslySetInnerHTML={{
                        __html: `window.lemonSqueezyAffiliateConfig = { store: "tailoredcv-ai" };`,
                    }}
                />
                <script src="https://lmsqueezy.com/affiliate.js" defer />
                {process.env.NODE_ENV === "development" && <ScreenSizeIndicator />}
            </body>
        </html>
    );
}

export const ErrorBoundary = () => {
    const error = useRouteError();
    const navigate = useNavigate();

    // If the error is a 404, display the 404 page
    if (isRouteErrorResponse(error) && error.status === 404) {
        return (
            <ErrorPage
                title="Page not found"
                message="The page you requested could not be found."
                backAction={() => {
                    navigate("/");
                }}
            />
        );
    }

    captureRemixErrorBoundaryError(error);
    return <ErrorPage title="Something went wrong" showRetry />;
};

export default function App() {
    return <Outlet />;
}
