import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { X } from "lucide-react";
import { Link } from "@remix-run/react";

export default function CookieConsent() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem("cookie-notice-seen");
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    const acknowledgeNotice = () => {
        localStorage.setItem("cookie-notice-seen", "true");
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <Card className="fixed bottom-4 left-4 max-w-xs z-50 shadow-lg">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-bold">Cookie Notice</CardTitle>
                <Button variant="ghost" size="sm" onClick={acknowledgeNotice}>
                    <X className="h-4 w-4" />
                    <span className="sr-only">Close</span>
                </Button>
            </CardHeader>
            <CardContent>
                <p className="text-xs text-muted-foreground mb-2">
                    At TailoredCV, we're committed to protecting your privacy. We only use
                    functional cookies that are essential for the proper functioning of our
                    services.
                </p>
                <p className="text-xs text-muted-foreground mb-2">
                    We do not use any marketing or tracking cookies, ensuring your browsing
                    experience remains private.
                </p>
                <p className="text-xs text-muted-foreground">
                    For more information, please read our{" "}
                    <Link
                        to={{
                            pathname: "/legal/privacy-policy",
                            hash: "9-cookies-and-their-use",
                        }}
                        className="text-xs text-primary hover:underline"
                    >
                        Privacy Policy
                    </Link>
                </p>
            </CardContent>
            <CardFooter className="flex justify-end">
                <Button size="sm" onClick={acknowledgeNotice}>
                    Got it
                </Button>
            </CardFooter>
        </Card>
    );
}
